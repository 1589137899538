import React from 'react';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';
import styled from 'styled-components';
import Layout from '../components/Layout';
import SEO from '../components/SEO';
import { Col, Container, Row, Section } from '../styles/common/Layout';
import mq from '../styles/media-queries';

const Persons = styled.div`
  display: grid;
  grid-gap: 0rem;
  grid-template-columns: repeat(auto-fit, minmax(175px, 1fr));
  padding-bottom: 12rem;
  overflow: hidden;
  ${mq.sm} {
    grid-template-columns: repeat(auto-fit, minmax(380px, 1fr));
  }
`;

const PersonStyles = styled.div`
  position: relative;
  a {
    text-decoration: none;
  }
`;

export default function KulturgesichterPage({ data }) {
  const persons = data.kulturgesichter.nodes;
  return (
    <Layout>
      <SEO
        title="Kulturgesichter"
        keywords={[`Kulturgesichter`, `NRW`, `Alarmstufe Rot`, `Alarmstufe`]}
        image={data.settings.image.asset.fixed}
      />
      <Section id="kulturgesichter">
        <Persons>
          {persons &&
            persons.map((person) => (
              <PersonStyles key={person.id}>
                <Img
                  loading="lazy"
                  fluid={person.image.asset.fluid}
                  alt={person.name}
                />
              </PersonStyles>
            ))}
        </Persons>
      </Section>
    </Layout>
  );
}

export const query = graphql`
  query KulturgesichterSettingsQuery {
    settings: sanityStoreSettings {
      name
      image {
        asset {
          fixed(width: 325) {
            ...GatsbySanityImageFixed
          }
        }
      }
    }
    kulturgesichter: allSanityPerson {
      nodes {
        image {
          asset {
            fluid(maxWidth: 768) {
              ...GatsbySanityImageFluid_noBase64
            }
          }
        }
        id
        name
      }
    }
  }
`;
